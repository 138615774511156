<template>
  <div>
    <b-row>
      <b-col
        cols="6"
      >
        <p class="clearfix mb-0">
          <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT  © {{ new Date().getFullYear() }}
            <b-link
              class="ml-25"
              href="https://eyetrax.net/"
              target="_blank"
            >Cancom Security Inc.</b-link>
            <span class="d-none d-sm-inline-block ml-2">Tel: </span>
            <a href="tel:1-855-322-5666">855-322-5666</a>
            <span class="d-none d-sm-inline-block ml-2">All rights Reserved</span>
          </span>
        </p>
      </b-col>
      <b-col
        cols="6"
      >
        <p class="clearfix mb-0">
          <span class="float-md-right d-block d-md-inline-block mt-25">
            <span class="d-none d-sm-inline-block">v{{ appVersion }}</span>
          </span>
        </p>
      </b-col>
    </b-row>
    <!-- Update Modal -->
    <b-modal
      id="updatemodal"
      ref="updatemodal"
      ok-title="Update"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      size="lg"
      title="Update Available"
      @close="showTPModal = false"
    >
      An update is available. Do you wish to refresh now?
    </b-modal>
  </div>
</template>

<script>
import { BLink, BCol, BRow } from 'bootstrap-vue'

// import { required } from 'vee-validate/dist/rules'

export default {
  components: {
    BLink,
    BCol,
    BRow,
  },
  data() {
    return {
      // Websockets props
      appVersion: 0,
    }
  },
  created() {
    console.log('VUE_APP_VERSION')
    console.log(process.env.VUE_APP_VERSION)
    this.appVersion = process.env.VUE_APP_VERSION
  },
}
</script>
