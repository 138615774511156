export default [
  {
    header: 'Forms & Tables',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Forms Elements',
    icon: 'CopyIcon',
    children: [
      {
        title: 'Input',
        route: 'forms-element-input',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Input Group',
        route: 'forms-element-input-group',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Input Mask',
        route: 'forms-element-input-mask',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Textarea',
        route: 'forms-element-textarea',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Auto Suggest',
        route: 'extensions-auto-suggest',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Checkbox',
        route: 'forms-element-checkbox',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Radio',
        route: 'forms-element-radio',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Switch',
        route: 'forms-element-switch',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Select',
        route: 'forms-element-select',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Vue Select',
        route: 'extensions-vue-select',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Spinbutton',
        route: 'forms-element-spinbutton',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'File Input',
        route: 'forms-element-file-input',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Quill Editor',
        route: 'extensions-quill-editor',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Form Datepicker',
        route: 'forms-element-datepicker',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Form Timepicker',
        route: 'forms-element-timepicker',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Date Time Picker',
        route: 'extensions-date-time-picker',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Form Rating',
        route: 'forms-element-rating',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Form Tag',
        route: 'forms-element-tag',
        resource: 'developer',
        action: 'read',
      },
    ],
  },
  {
    title: 'Form Layout',
    route: 'form-layout',
    icon: 'CopyIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Form Wizard',
    route: 'form-wizard',
    icon: 'PackageIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Form Validation',
    route: 'form-validation',
    icon: 'CheckCircleIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Form Repeater',
    route: 'form-repeater',
    icon: 'CopyIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'BS Table',
    route: 'table-bs-table',
    icon: 'ServerIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Good Table',
    route: 'table-good-table',
    icon: 'GridIcon',
    resource: 'developer',
    action: 'read',
  },
]
