export default [
  {
    header: 'User Interface',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Typography',
    route: 'ui-typography',
    icon: 'TypeIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Colors',
    route: 'ui-colors',
    icon: 'DropletIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Feather',
    route: 'ui-feather',
    icon: 'EyeIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Cards',
    icon: 'CreditCardIcon',
    tag: 'new',
    tagVariant: 'light-success',
    children: [
      {
        title: 'Basic',
        route: 'card-basic',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Advance',
        route: 'card-advance',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Statistics',
        route: 'card-statistic',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Analytics',
        route: 'card-analytic',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Card Actions',
        route: 'card-action',
        resource: 'developer',
        action: 'read',
      },
    ],
  },
  {
    title: 'Components',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Alert',
        route: 'components-alert',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Aspect',
        route: 'components-aspect',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Avatar',
        route: 'components-avatar',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Badge',
        route: 'components-badge',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Breadcrumb',
        route: 'components-breadcrumb',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Button',
        route: 'components-button',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Button Group',
        route: 'components-button-group',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Button Toolbar',
        route: 'components-button-toolbar',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Calendar',
        route: 'components-calendar',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Carousel',
        route: 'components-carousel',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Collapse',
        route: 'components-collapse',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Dropdown',
        route: 'components-dropdown',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Embed',
        route: 'components-embed',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Image',
        route: 'components-image',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'List Group',
        route: 'components-list-group',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Media Objects',
        route: 'components-media',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Modal',
        route: 'components-modal',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Nav',
        route: 'components-nav',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Overlay',
        route: 'components-overlay',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Pagination',
        route: 'components-pagination',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Pagination Nav',
        route: 'components-pagination-nav',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Pill',
        route: 'components-pill',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Pill Badge',
        route: 'components-pill-badge',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Popover',
        route: 'components-popover',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Progress',
        route: 'components-progress',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Sidebar',
        route: 'components-sidebar',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'spinner',
        route: 'components-spinner',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Tab',
        route: 'components-tab',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Time',
        route: 'components-time',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Timeline',
        route: 'components-timeline',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Toasts',
        route: 'components-toasts',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Tooltip',
        route: 'components-tooltip',
        resource: 'developer',
        action: 'read',
      },
    ],
  },
  {
    title: 'Extensions',
    icon: 'PlusCircleIcon',
    children: [
      {
        title: 'Sweet Alert',
        route: 'extensions-sweet-alert',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Toastification',
        route: 'extensions-toastification',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Slider',
        route: 'extensions-slider',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Drag & Drop',
        route: 'extensions-drag-and-drop',
        resource: 'developer',
        action: 'read',
      },

      {
        title: 'Tour',
        route: 'extensions-tour',
        resource: 'developer',
        action: 'read',
      },

      {
        title: 'Clipboard',
        route: 'extensions-clipboard',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Context Menu',
        route: 'extensions-context-menu',
        resource: 'developer',
        action: 'read',
      },

      {
        title: 'Swiper',
        route: 'extensions-swiper',
        resource: 'developer',
        action: 'read',
      },

      // {
      //   title: 'Tree',
      //   route: 'extensions-tree',
      // },

      {
        title: 'I18n',
        route: 'extensions-i18n',
        resource: 'developer',
        action: 'read',
      },
    ],
  },
  {
    title: 'Page Layouts',
    icon: 'LayoutIcon',
    children: [
      {
        title: 'Collapsed Menu',
        route: 'page-layout-collapsed-menu',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Layout Boxed',
        route: 'page-layout-boxed-layout',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Without Menu',
        route: 'page-layout-without-menu',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Layout Empty',
        route: 'page-layout-layout-empty',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Layout Blank',
        route: 'page-layout-layout-blank',
        resource: 'developer',
        action: 'read',
      },
    ],
  },
]
