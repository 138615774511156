export default [
  {
    title: 'Reports',
    route: 'apps-reports-type',
    icon: 'BookOpenIcon',
    resource: 'reports',
    action: 'read',
  },
  {
    title: 'Cameras',
    route: 'apps-cameraconsole-list',
    icon: 'CameraIcon',
    resource: 'cameras',
    action: 'read',
  },
  {
    title: 'Cameras Management',
    route: 'apps-cameras-management-list',
    icon: 'CameraIcon',
    resource: 'camerasmanagement',
    action: 'read',
  },
  {
    header: 'System Management',
  },
  {
    title: 'Users',
    route: 'apps-users-list',
    icon: 'UserIcon',
    resource: 'users',
    action: 'read',
  },
  {
    title: 'Client Account',
    route: 'apps-clients-list',
    icon: 'UsersIcon',
    resource: 'clients',
    action: 'read',
  },
  {
    title: 'Abilities Templates',
    route: 'apps-abilities-list',
    icon: 'CheckSquareIcon',
    resource: 'abilitiestemplates',
    action: 'read',
  },
  {
    title: 'Firmware Management',
    route: 'apps-firmware-list',
    icon: 'FileIcon',
    resource: 'firmwaremanagement',
    action: 'read',
  },
  {
    header: 'Apps & Pages',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Email',
    route: 'apps-email',
    icon: 'MailIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Chat',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Todo',
    route: 'apps-todo',
    icon: 'CheckSquareIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
    resource: 'developer',
    action: 'read',
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-invoice-list',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Preview',
        route: { name: 'apps-invoice-preview', params: { id: 4987 } },
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Edit',
        route: { name: 'apps-invoice-edit', params: { id: 4987 } },
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Add',
        route: { name: 'apps-invoice-add' },
        resource: 'developer',
        action: 'read',
      },
    ],
  },
  {
    title: 'eCommerce',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Shop',
        route: 'apps-e-commerce-shop',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Details',
        route: {
          name: 'apps-e-commerce-product-details',
          params: { slug: 'apple-watch-series-5-27' },
        },
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Wishlist',
        route: 'apps-e-commerce-wishlist',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Checkout',
        route: 'apps-e-commerce-checkout',
        resource: 'developer',
        action: 'read',
      },
    ],
  },
  {
    title: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Account Settings',
        route: 'pages-account-setting',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Profile',
        route: 'pages-profile',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Faq',
        route: 'pages-faq',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Knowledge Base',
        route: 'pages-knowledge-base',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Pricing',
        route: 'pages-pricing',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Blog',
        children: [
          {
            title: 'List',
            route: 'pages-blog-list',
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Detail',
            route: { name: 'pages-blog-detail', params: { id: 1 } },
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Edit',
            route: { name: 'pages-blog-edit', params: { id: 1 } },
            resource: 'developer',
            action: 'read',
          },
        ],
      },
      {
        title: 'Mail Templates',
        children: [
          {
            title: 'Welcome',
            href:
              'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Reset Password',
            href:
              'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Verify Email',
            href:
              'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Deactivate Account',
            href:
              'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Invoice',
            href:
              'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
            resource: 'developer',
            action: 'read',
          },
          {
            title: 'Promotional',
            href:
              'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
            resource: 'developer',
            action: 'read',
          },
        ],
      },
      {
        title: 'Miscellaneous',
        icon: 'CircleIcon',
        children: [
          {
            title: 'Coming Soon',
            route: 'misc-coming-soon',
            resource: 'developer',
            action: 'read',
            target: '_blank',
          },
          {
            title: 'Not Authorized',
            route: 'misc-not-authorized',
            resource: 'developer',
            action: 'read',
            target: '_blank',
          },
          {
            title: 'Under Maintenance',
            route: 'misc-under-maintenance',
            resource: 'developer',
            action: 'read',
            target: '_blank',
          },
          {
            title: 'Error',
            route: 'misc-error',
            resource: 'developer',
            action: 'read',
            target: '_blank',
          },
        ],
      },
    ],
  },
]
