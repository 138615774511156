export default [
  {
    header: 'Apps',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Reports',
        icon: 'BookOpenIcon',
        resource: 'reports',
        action: 'read',
        children: [
          {
            title: 'Reports',
            route: 'apps-reports-type',
          },
        ],
      },
      {
        title: 'Email',
        route: 'apps-email',
        icon: 'MailIcon',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Chat',
        route: 'apps-chat',
        icon: 'MessageSquareIcon',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Todo',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Calendar',
        route: 'apps-calendar',
        icon: 'CalendarIcon',
        resource: 'developer',
        action: 'read',
      },
      {
        title: 'Invoice',
        icon: 'FileTextIcon',
        resource: 'developer',
        action: 'read',
        children: [
          {
            title: 'List',
            route: 'apps-invoice-list',
          },
          {
            title: 'Preview',
            route: { name: 'apps-invoice-preview', params: { id: 4987 } },
          },
          {
            title: 'Edit',
            route: { name: 'apps-invoice-edit', params: { id: 4987 } },
          },
          {
            title: 'Add',
            route: { name: 'apps-invoice-add' },
          },
        ],
      },
      {
        title: 'eCommerce',
        icon: 'ShoppingCartIcon',
        resource: 'developer',
        action: 'read',
        children: [
          {
            title: 'Shop',
            route: 'apps-e-commerce-shop',
          },
          {
            title: 'Details',
            route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
          },
          {
            title: 'Wishlist',
            route: 'apps-e-commerce-wishlist',
          },
          {
            title: 'Checkout',
            route: 'apps-e-commerce-checkout',
          },
        ],
      },
      {
        title: 'Cameras Console',
        icon: 'CameraIcon',
        resource: 'cameras',
        action: 'read',
        children: [
          {
            title: 'Cameras',
            route: 'apps-cameraconsole-list',
          },
          // {
          //   title: 'Add',
          //   route: { name: 'apps-abilities-add', params: { tempatename: 'Management' } },
          // },
          // {
          //   title: 'Edit',
          //   route: { name: 'apps-abilities-edit', params: { tempatename: 'Management' } },
          // },
        ],
      },
      {
        title: 'User',
        icon: 'UserIcon',
        resource: 'users',
        action: 'read',
        children: [
          {
            title: 'List',
            route: 'apps-users-list',
          },
          {
            title: 'View',
            route: { name: 'apps-users-view', params: { username: '' } },
          },
          {
            title: 'Edit',
            route: { name: 'apps-users-edit', params: { username: '' } },
          },
        ],
      },
      {
        title: 'Client Account',
        icon: 'UsersIcon',
        resource: 'clients',
        action: 'read',
        children: [
          {
            title: 'List',
            route: 'apps-clients-list',
          },
          {
            title: 'View',
            route: { name: 'apps-clients-view', params: { clientname: '' } },
          },
          {
            title: 'Edit',
            route: { name: 'apps-clients-edit', params: { clientname: '' } },
          },
        ],
      },
      {
        title: 'Cameras Management',
        icon: 'CameraIcon',
        resource: 'camerasmanagement',
        action: 'read',
        children: [
          {
            title: 'List',
            route: 'apps-cameras-management-list',
          },
          // {
          //   title: 'Add',
          //   route: { name: 'apps-abilities-add', params: { tempatename: 'Management' } },
          // },
          // {
          //   title: 'Edit',
          //   route: { name: 'apps-abilities-edit', params: { tempatename: 'Management' } },
          // },
        ],
      },
      {
        title: 'Abilities Templates',
        icon: 'CheckSquareIcon',
        resource: 'abilitiestemplates',
        action: 'read',
        children: [
          {
            title: 'List',
            route: 'apps-abilities-list',
          },
          {
            title: 'Add',
            route: { name: 'apps-abilities-add', params: { tempatename: 'Management' } },
          },
          {
            title: 'Edit',
            route: { name: 'apps-abilities-edit', params: { tempatename: 'Management' } },
          },
        ],
      },
      {
        title: 'Firmware Management',
        icon: 'FileIcon',
        resource: 'firmwaremanagement',
        action: 'read',
        children: [
          {
            title: 'List',
            route: 'apps-firmware-list',
          },
        ],
      },
    ],
  },
]
